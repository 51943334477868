import { CallOutData } from './types';

// TODO: fix hydration errors caused by using jsx elements with tailwind styles

export const resourcesData: CallOutData = {
  topSection: [
    {
      barColor: 'cyan',
      bgColor: 'pastelBlue',
      ctaText: 'Prescribing Information ',
      description: 'Review the Full Prescribing Information for caps{Nerlynx}.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/full-prescribing-information.pdf',
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelGray',
      ctaText: 'Enrollment Form',
      description:
        'Prescribe caps{Nerlynx} to your appropriate patients with the Enrollment Form.',
      buttons: [
        {
          text: 'English',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/enrollment-form.pdf',
          noModal: true,
        },
        {
          text: 'Español',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/ppl-enrollment-form-espanol-2023.pdf',
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelYellow',
      ctaText: 'Dosing and Administration Guide ',
      description:
        'Review detailed information for your office staff about dosing, dose escalation, and treatment management.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/dosing-and-administration-guide.pdf',
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelBlue',
      ctaText: (
        <p tw="[font-size:24px]">
          ExteNET Trial
          <br />
          (eBC) Data
        </p>
      ),
      description:
        'Review a brochure describing the efficacy and safety data for caps{Nerlynx} in patients with early-stage HER2+ breast cancer and subgroups of clinical interest.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/ExteNET-Trial-eBC-Clinical-Outcomes.pdf',
          noModal: true,
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelYellow',
      ctaText: 'eBC Treatment Completion Data',
      description: (
        <p tw="text-sm leading-5">
          Review a brochure detailing a descriptive analysis of patients with
          early-stage HER2+ breast cancer who completed treatment with{' '}
          <span tw="uppercase">Nerlynx</span> as planned in the ExteNET trial.
          <sup>1,</sup>
          <span tw="align-top">*</span>
        </p>
      ),
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/ExteNET-Trial-eBC-Treatment-Completion-Results.pdf',
          noModal: true,
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelBlue',
      ctaText: (
        <p tw="[font-size:24px]">
          eBC
          <br />
          HR- Data
        </p>
      ),
      description:
        'Review a brochure detailing descriptive analyses of patients with early-stage HER2+ HR- breast cancer.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/ExteNET-Trial-eBC-HR-Efficacy-and-Safety-Outcomes.pdf',
          noModal: true,
        },
      ],
    },
    {
      barColor: 'cyan',
      bgColor: 'pastelPink',
      ctaText: 'NALA Trial (mBC) Data ',
      description:
        'Review a brochure describing the efficacy and safety data for caps{Nerlynx} + capecitabine in patients with metastatic HER2+ breast cancer.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/NALA-Trial-mBC-Brochure.pdf',
        },
      ],
    },
    
  ],
  pumaSection: [
    {
      barColor: 'cyan',
      bgColor: 'pastelGrayDark',
      ctaText: 'Enrollment Form',
      description:
        'Prescribe caps{Nerlynx} to your appropriate patients with the Enrollment Form.',
      buttons: [
        {
          text: 'English',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/enrollment-form.pdf',
        },
        {
          text: 'Español',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/ppl-enrollment-form-espanol-2023.pdf',
        },
      ],
    },
    {
      description:
        'Eligible commercially insured patients treated with NERLYNX can be enrolled for co-pay assistance.',
      barColor: 'cyan',
      bgColor: 'pastelGrayDark',
      ctaText: 'Co-pay Card Program',
      buttons: [
        {
          text: 'Go to patient enrollment page',
          download: false,
          arrowStyle: 'right',
          to: 'https://pumabiotechnology.com/copayredirect.html',
          noModal: false,
        },
      ],
    },
  ],
  bottomSection: [
    {
      barColor: 'cyan',
      bgColor: 'pastelGrayDark',
      ctaText: 'HUB Resources Brochure',
      description:
        'Review a brochure describing the upgraded patient support services available from Puma Patient Lynx.',
      buttons: [
        {
          text: 'Download',
          download: true,
          arrowStyle: 'none',
          to: '/pdf/HUB.pdf',
          noModal: true,
        },
      ],
    },
  ],
};
